body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-y: scroll;
}

.unselectable {
  pointer-events: none;
  user-select: none;
}


.selectable {
  pointer-events: all;
  user-select: all;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.glow-on-hover {
  width: 101px;
  height: 48px;
  border: none;
  outline: none;
  color: #fff;
  background: #0d6efd;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #0c90fc,
    #148cfc,
    #3680fc,
    #5fb0fc,
    #7de3fc,
    #8ed9fc,
    #5f98fc,
    #1470fc
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(6px);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  animation: glowing 15s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
}

.glow-on-hover:active {
  color: #fff;
}

/* .glow-on-hover:active:after {
    background: transparent;
} */

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0d6efd;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.custom-highlight ::selection {
  background: #0275d8;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.blue-gradient {
  background: linear-gradient(
    170deg,
    #0274d8fb,
    #eed371b5
  );
  background-size: 400% 400%;
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
}